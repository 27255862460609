.loader {
  max-width: 15rem;
  width: 100%;
  height: auto;
  stroke-linecap: butt;

  circle {
    fill: none;
    animation-name: preloader;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform-origin: 170px 170px;
    will-change: transform;

    &:nth-of-type(1) {
      stroke-dasharray: 550px;
    }

    &:nth-of-type(2) {
      stroke-dasharray: 500px;
    }

    &:nth-of-type(3) {
      stroke-dasharray: 450px;
    }

    &:nth-of-type(4) {
      stroke-dasharray: 400px;
    }

    &:nth-of-type(5) {
      stroke-dasharray: 350px;
    }

    &:nth-of-type(6) {
      stroke-dasharray: 300px;
    }

    &:nth-of-type(7) {
      stroke-dasharray: 450px;
    }

    &:nth-of-type(8) {
      stroke-dasharray: 200px;
      /*stroke-dasharray: 200, 80, 100;*/
    }

    &:nth-of-type(9) {
      stroke-dasharray: 150px;
      /*stroke-dasharray: 60, 100, 60;*/
    }

    &:nth-of-type(10) {
      stroke-dasharray: 100px;
    }

    @for $i from 1 through 10 {
      &:nth-of-type(#{$i}) {
        animation-delay: -#{$i * 0.15}s;
      }
    }
  }

  .circle1 {
    stroke-width: 58;
  }

  .circle12 {
    stroke-width: 59;
  }

  .circle13 {
    stroke-width: 60;
  }

  .circle2 {
    stroke-width: 42;
  }

  .circle22 {
    stroke-width: 44;
  }

  .circle23 {
    stroke-width: 46;
  }

  .circle3 {
    stroke-width: 42;
  }

  .circle32 {
    stroke-width: 44;
  }

  .circle33 {
    stroke-width: 46;
  }

  .circle4 {
    stroke-width: 0;
    fill: #ff9200;
  }

  @keyframes preloader {
    50% {
      transform: rotate(360deg);
    }
  }
}
