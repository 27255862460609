@font-face {
  font-family: DINPro-Black;
  src: url('Assets/fonts/TYPOS/DINPro-Black.otf');
  font-weight: bold;
}
@font-face {
  font-family: DINPro-Bold;
  src: url('Assets/fonts/TYPOS/DINPro-Bold.otf');
  font-weight: bold;
}
@font-face {
  font-family: DINPro-Light;
  src: url('Assets/fonts/TYPOS/DINPro-Light.otf');
  font-weight: bold;
}
@font-face {
  font-family: DINPro-Medium;
  src: url('Assets/fonts/TYPOS/DINPro-Medium.otf');
  font-weight: bold;
}
@font-face {
  font-family: DINPro-Regular;
  src: url('Assets/fonts/TYPOS/DINPro-Regular.otf');
  font-weight: bold;
}
@font-face {
  font-family: MinionPro-Regular;
  src: url('Assets/fonts/TYPOS/MinionPro-Regular.otf');
  font-weight: bold;
}
@font-face {
  font-family: Nexa-Bold;
  src: url('Assets/fonts/TYPOS/Nexa Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: Nexa-Book;
  src: url('Assets/fonts/TYPOS/Nexa Book.ttf');
  font-weight: bold;
}
@font-face {
  font-family: Nexa-Heavy;
  src: url('Assets/fonts/TYPOS/Nexa Heavy.ttf');
  font-weight: bold;
}
@font-face {
  font-family: Nexa-Regular;
  src: url('Assets/fonts/TYPOS/Nexa Regular.ttf');
  font-weight: bold;
}
@font-face {
  font-family: Nexa-XBold;
  src: url('Assets/fonts/TYPOS/Nexa XBold.ttf');
  font-weight: bold;
}
