.container {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0);
}
.product-name {
  font-family: Nexa-XBold;
  font-size: 9.5pt;
  letter-spacing: -0.025em;
}
.product-description {
  font-family: DINPro-regular;
  font-size: 7.5pt;
  line-height: 8pt;
  letter-spacing: -0.025em;
  margin-bottom: 2mm;
}
.Body {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.Body .secondaryImage {
  position: absolute;
  bottom: 0;
  right: 0;
}

.LePlus {
  margin-left: -3mm;
  margin-top: 3mm;
}
.drag {
  cursor: move;
}
