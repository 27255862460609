@mixin add-font($name, $path) {
  @font-face {
    font-family: quote($name);
    src: url('#{$path}/#{$name}.eot');
    src: url('#{$path}/#{$name}.eot?#iefix') format('embedded-opentype'),
      url('#{$path}/#{$name}.woff2') format('woff2'),
      url('#{$path}/#{$name}.woff') format('woff');
  }
}

@include add-font('ScandiaWebBold', 'Scandia/ScandiaWebBold');
@include add-font('ScandiaWebBoldItalic', 'Scandia/ScandiaWebBoldItalic');
@include add-font('ScandiaWebLight', 'Scandia/ScandiaWebLight');
@include add-font('ScandiaWebLightItalic', 'Scandia/ScandiaWebLightItalic');
@include add-font('ScandiaWebMedium', 'Scandia/ScandiaWebMedium');
@include add-font('ScandiaWebMediumItalic', 'Scandia/ScandiaWebMediumItalic');
@include add-font('ScandiaWebRegular', 'Scandia/ScandiaWebRegular');
@include add-font('ScandiaWebRegularItalic', 'Scandia/ScandiaWebRegularItalic');
@include add-font('ScandiaWebStencil', 'Scandia/ScandiaWebStencil');
